<template>
  <v-row class="rounded-lg startup-list-card" justify="center">
    <v-col cols="3">
      <base-img
        cover
        class="rounded-lg"
        max-width="6rem"
        :aspect-ratio="1"
        :src="getSrcImage"
      />
    </v-col>
    <v-col cols="8" class="pl-5 py-0">
      <v-row align-content="space-between">
        <v-col cols="12">
          <h6
            class="text-h6 text-capitalize mb-0"
            style="word-break: break-word"
          >
            {{ prize.name }}
          </h6>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-row justify="space-between" align="center">
            <v-col cols="auto">
              <p class="text-justify font-weight-bold mb-0">
                <span class="text-h5 primary--text"> {{ prize.coins }} </span>
                <span class="text-title">Puntos</span>
              </p>
            </v-col>
            <v-spacer />

            <slot />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider class="mt-4" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ItemPrizesComponent",
  props: {
    prize: { type: Object, required: true },
  },
  computed: {
    getSrcImage() {
      return this.prize?.image ?? require("@/assets/no-image.png");
    },
  },
};
</script>
